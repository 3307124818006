import { showNotification } from '@mantine/notifications'
import { useEffect } from 'react'

export const useToastError = (error: string | undefined): void => {
  useEffect(() => {
    if (error)
      showNotification({
        title: 'Error',
        message: error,
        color: 'red',
        autoClose: 5000,
      })
  }, [error])
}
